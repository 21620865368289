import React, { useState, useRef, useEffect } from "react"
import { HexColorPicker, HexColorInput } from "react-colorful";
import "react-colorful/dist/index.css";
import styles from "./ColorInput.module.scss"

export default function ColorInput(props) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const btnColorPicker = useRef()
  const colorPicker = useRef()

  useEffect(() => {
    if (typeof document !== undefined) {
      document.addEventListener("mousedown", handleWindowClick);
      return () => {
        document.removeEventListener("mousedown", handleWindowClick);
      };
    }
  }, []);

  const handleWindowClick = event => {
    if (colorPicker.current.contains(event.target)) {
      return
    }
    if(event.target === btnColorPicker.current) {
      return
    }
    setDisplayColorPicker(false)
  }

  const handleButtonClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  }

  return(
    <>
      <div className="columns">
        <div className="column is-3 is-flex is-flex-direction-row is-align-items-center">
          <div className="has-text-weight-medium">
            {props.title}:
          </div>
        </div>
        <div className="column is-flex is-flex-direction-row is-align-items-center">
          <button
            ref={btnColorPicker}
            className={styles.colorBtn}
            type="button"
            style={{ backgroundColor: props.value }}
            onClick={handleButtonClick}>
          </button>
          <HexColorInput
            className={styles.hexColorInput}
            color={props.value}
            onChange={color => props.handleChange(color)}
          />
        </div>
      </div>
      <div className={`columns ${displayColorPicker ? '' : 'is-hidden'}`}>
        <div className="column is-inline-block is-offset-3">
          <div className="hex-color-picker is-inline-block" ref={colorPicker}>
            {displayColorPicker ? <HexColorPicker color={props.value} onChange={color => props.handleChange(color)} /> : null}
          </div>
        </div>
      </div>
    </>
  )
}
