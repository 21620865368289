import React from "react"
import { camelCase, kebabCase } from "lodash"
import styles from "./SwitchCheckboxInput.module.scss"

export default function SwitchCheckboxInput(props) {
  const name = camelCase(props.title)
  const id = kebabCase(props.title)

  return(
    <div className="columns">
      <div className="column is-3 is-flex is-flex-direction-row is-align-items-center">
        <span className="has-text-weight-medium">
          {props.title}:
        </span>
      </div>
      <div className="column is-flex is-flex-direction-row is-align-items-center">
        <div className={styles.switch}>
          <input
            className={styles.switchInput}
            type="checkbox"
            id={id}
            name={name}
            checked={props.checked}
            onChange={props.handleChange}>
          </input>
          <label className={styles.switchLabel} htmlFor={id}>
            {props.title}
          </label>
        </div>
      </div>
    </div>
  )
}
