import React from "react"
import NumberInput from "./inputs/NumberInput"
import ColorInput from "./inputs/ColorInput"
import SwitchCheckboxInput from "./inputs/SwitchCheckboxInput"
import CopyButton from "../copy-button/CopyButton"

export default function BoxShadowSettings(props) {
  return(
    <>
      <h2 className="title is-4 is-hidden">Box-Shadow</h2>
      <NumberInput
        title="Horizontal Length"
        min="-100"
        max="100"
        appendix="px"
        value={props.boxShadow.horizontalLength}
        handleChange={props.handleBoxShadowChange}
      />
      <NumberInput
        title="Vertical Length"
        min="-100"
        max="100"
        appendix="px"
        value={props.boxShadow.verticalLength}
        handleChange={props.handleBoxShadowChange}
      />
      <NumberInput
        title="Blur Radius"
        min="0"
        max="200"
        appendix="px"
        value={props.boxShadow.blurRadius}
        handleChange={props.handleBoxShadowChange}
      />
      <NumberInput
        title="Spread"
        min="-100"
        max="100"
        appendix="px"
        value={props.boxShadow.spread}
        handleChange={props.handleBoxShadowChange}
      />
      <ColorInput
        title="Color"
        value={props.boxShadow.color}
        handleChange={props.handleBoxShadowColorChange}
      />
      <NumberInput
        title="Opacity"
        min="0"
        max="1"
        step="0.01"
        value={props.boxShadow.opacity}
        handleChange={props.handleBoxShadowChange}
      />
      <SwitchCheckboxInput
        title="Inset"
        checked={props.boxShadow.inset}
        handleChange={props.handleBoxShadowChange}
      />
      <CopyButton code={props.code}>Copy CSS</CopyButton>
    </>
  )
}
