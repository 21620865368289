import React from "react"
import "./InputRange.scss"

export default function InputRange(props) {
  return(
    <input
      type="range"
      id={props.id}
      name={props.name}
      min={props.min}
      max={props.max}
      step={props.step}
      value={props.value}
      onChange={props.handleChange}>
    </input>
  )
}
