export default function hexToRGB(hex) {
  // let r = 0, g = 0, b = 0;

  // if (h.length == 4) {
  //   r = "0x" + h[1] + h[1];
  //   g = "0x" + h[2] + h[2];
  //   b = "0x" + h[3] + h[3];

  // } else if (h.length == 7) {
  //   r = "0x" + h[1] + h[2];
  //   g = "0x" + h[3] + h[4];
  //   b = "0x" + h[5] + h[6];
  // }

  // return {
  //   r: r,
  //   g: g,
  //   b: b
  // }

  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}
