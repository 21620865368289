import React, { useState } from "react"
import BoxShadowSettings from "../components/box-shadow/BoxShadowSettings"
import BoxShadowPreview from "../components/box-shadow/BoxShadowPreview"
import hexToRGB from "../utils/hexToRGB"

import Layout from "../components/layout/Layout"
import SEO from "../components/seo"

const BoxShadow = () => {
  const [boxShadow, setBoxShadow] = useState({
    horizontalLength: 0,
    verticalLength: 0,
    blurRadius: 5,
    spread: 0,
    color: '#000000',
    opacity: 0.25,
    inset: false
  })

  const rgb = hexToRGB(boxShadow.color)
  const boxShadowStyles = {
    boxShadow: `${boxShadow.inset ? 'inset' : ''} ${boxShadow.horizontalLength}px ${boxShadow.verticalLength}px ${boxShadow.blurRadius}px ${boxShadow.spread}px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${boxShadow.opacity})`
  }
  const code = `-webkit-box-shadow: ${boxShadowStyles.boxShadow};
-moz-box-shadow: ${boxShadowStyles.boxShadow};
box-shadow: ${boxShadowStyles.boxShadow};`

  const handleBoxShadowChange = event => {
    const {name, value, type, checked} = event.target
    setBoxShadow(prevState => {
      return {
        ...prevState,
        [name]: (type === 'checkbox') ? checked : value
      }
    })
  }

  const handleBoxShadowColorChange = color => {
    setBoxShadow(prevState => {
      return {
        ...prevState,
        color: color
      }
    })
  }

  return(
    <Layout>
      <SEO title="CSS Box-Shadow-Generator" />
      <section className="section">
        <h1 className="title is-1 has-text-centered mt-6 is-hidden">Box-Shadow-Generator</h1>
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column is-5">
              <BoxShadowSettings
                boxShadow={boxShadow}
                handleBoxShadowChange={handleBoxShadowChange}
                handleBoxShadowColorChange={handleBoxShadowColorChange}
                code={code}
              />
            </div>
            <div className="column is-5 is-offset-2">
              <BoxShadowPreview
                boxShadowStyles={boxShadowStyles}
                code={code}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BoxShadow
