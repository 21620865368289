import React, { useState, useEffect } from "react"
import styles from "./CopyButton.module.scss"
import ClipboardJS from "clipboard"

export default function CopyButton(props) {
  const [displayCopyNotice, setDisplayCopyNotice] = useState(false)

  useEffect(() => {
    if (typeof document !== undefined) {
      new ClipboardJS('.css-gems-btn');
    }
  }, []);

  return(
    <>
      <button
        className={`${styles.button} css-gems-btn`}
        onClick={() => setDisplayCopyNotice(true)}
        onMouseLeave={() => setDisplayCopyNotice(false)}
        data-clipboard-text={props.code}>
          {props.children}
      </button>
      <span className={`ml-3 ${displayCopyNotice ? '' : 'is-hidden'}`}>
        Copied!
      </span>
    </>
  )
}
