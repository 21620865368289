import React from "react"

export default function BoxShadowPreview(props) {
  return(
    <div style={{
      ...props.boxShadowStyles,
      height: 300,
      backgroundColor: "white",
      borderRadius: 8,
      padding: 25
    }}>
      <h3 className="title is-6">Code:</h3>
      <pre style={{
        backgroundColor: "transparent",
        padding: 0,
        fontSize: "0.75rem"
      }}>
        {props.code}
      </pre>
    </div>
  )
}
