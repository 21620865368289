import React from "react"
import InputRange from "../../input-range/InputRange"
import { camelCase, kebabCase } from "lodash"
import styles from "./NumberInput.module.scss"

export default function NumberInput(props) {
  const name = camelCase(props.title)
  const id = kebabCase(props.title)

  return(
    <div className="columns">
      <div className="column is-3 is-flex is-flex-direction-row is-align-items-center">
        <label className="has-text-weight-medium" htmlFor={id}>
          {props.title}:
        </label>
      </div>
      <div className="column is-flex is-flex-direction-row is-align-items-center">
        <input
          className={styles.inputNumber}
          type="number"
          id={id}
          name={name}
          min={props.min}
          max={props.max}
          value={props.value}
          onChange={props.handleChange}>
        </input>
        {props.appendix ?
        <span className="input-appendix ml-2 mr-3">{props.appendix}</span>
        : null}
        <InputRange
          name={name}
          min={props.min}
          max={props.max}
          step={props.step}
          value={props.value}
          handleChange={props.handleChange}
        />
      </div>
    </div>
  )
}
